<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :width="popupWidth" :show-close="false">
      <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="form.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="confirmPassword">
          <el-input v-model="form.confirmPassword"></el-input>
        </el-form-item>
        <el-row type="flex" justify="center">
          <el-button type="primary" icon="el-icon-check" @click="Save">确认</el-button>
          <el-button type="default" icon="el-icon-close" @click="Cancel">取消</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '@/assets/js/http'
export default {
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: "",
        confirmPassword: ""
      },
      rules: {
        oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        confirmPassword: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }],
      }
    };
  },

  props: {
    dialogTitle: {
      type: String,
      default: '标题',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    popupWidth: {
      type: String,
      default: '30%',
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        // 当visible改变的时候，触发父组件的 updateVisible方法，在该方法中更改传入子组件的 centerDialogVisible的值
        this.$emit('updateVisible', val);
      },
    },
  },
  methods: {
    Cancel() {
      this.$emit('resetPopupData', this.from);
    },
    Save() {
      // this.$emit('submitPopupData', this.form);
      
      this.$refs.validate(async (valid) => {
        if (valid) {
          if(this.form.newPassword !== this.form.confirmPassword) {
            this.$message.error('两次密码不一致')
            return
          }
          try {
            this.$loading()
            await post({
              url: '/admin/index/changePassword',
              data: this.form
            })
            this.$message.success('修改成功')
            localStorage.removeItem('vuex')
            window.location.href = '/admin/#/login'
          } catch (err) {

          } finally {
            this.$loading().close()
          }
        }
      })
    },
    // handleClose() {
    //   this.$emit('handleClose');
    // },
  },
};
</script>

<style></style>

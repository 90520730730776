import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
import {get} from '@/assets/js/http'
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        config: {},
        menu:[]
    },
    getters:{
        level:state=>{
            let keys = ['level_hzd_f_discount','level_hzd_s_discount','level_xxd_f_discount','level_xxd_s_discount','level_qjd_f_discount','level_qjd_s_discount','']
            return  state.config.list?.basics?.list.filter((item)=>{
                return keys.includes(item.name)
            })
        },
        privacy_level:state=>{
            let keys = ['privacy_rate_1','privacy_rate_2']
            return  state.config.list?.basics?.list.filter((item)=>{
                return keys.includes(item.name)
            })
        },
        level_type:()=>{
            return{
                0:'暂无等级',
                10:'合作店',
                20:'形象店',
                30:'旗舰店',
            }
        },
        in_order_status:()=>{
            return {
                1:{label:'正常',status:'primary'},
                2:{label:'作废',status:'danger'},
            }
        },
        user_level:()=>{
            return {
                0:'暂无等级',
                10:'市代',
                20:'省代',
                30:'股东'
            }
        },
        use_private_level:()=>{
            return {
                0:'暂无等级',
                1:'私密合作店',
                2:'私密合伙人',
            }
        }
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setConfig(state, payload){
            state.config = payload;
        },
        setMenu(state,menu){
            state.menu = menu
        }
    },

    actions: {
        getConfig(ctx){
            get({
                url:'/admin/routine.config/index'
            }).then(res=>{
                ctx.commit('setConfig',res)
            })
        },
        setMenu(ctx,menu){
            ctx.commit('setMenu',menu)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key:'adminVuex'
        })
    ]
})
